.welcome {
  padding: 0 0 20px;
  background: #101c49;

  .row {
    .first {
      width: 100%;
    }

    .second {
      width: 100%;
    }

    @media (width >= 720px) {
      display: flex;
      flex-flow: row wrap;

      .first {
        width: 300px;
        min-width: 50%;
      }

      .second {
        width: 300px;
        margin-left: auto;
      }
    }

    @media (width >= 920px) {
      .first {
        width: 400px;
      }

      .second {
        width: 400px;
      }
    }

    @media (width >= 1220px) {
      .first {
        width: 500px;
      }

      .second {
        width: 500px;
      }
    }

    @media (width >= 1440px) {
      .first {
        width: 600px;
      }

      .second {
        width: 600px;
      }
    }
  }

  .title {
    font-family: Arial, sans-serif;
    color: #fff;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 40px;

    @media (width >= 720px) {
      & {
        font-size: 5rem;
      }
    }

    @media (width >= 920px) {
      & {
        font-size: 7rem;
      }
    }

    @media (width >= 1220px) {
      & {
        font-size: 8rem;
      }
    }

    @media (width >= 1440px) {
      & {
        font-size: 9rem;
      }
    }
  }

  .video {
    position: relative;

    img {
      background: #d9d9d9;
      border-radius: 50px;
    }

    .play {
      position: absolute;
      width: 163px;
      height: 171px;
      left: calc(50% - 80px);
      top: calc(50% - 85px);
    }
  }

  .logo {
    position: relative;
    width: 216px;
    height: 216px;
    margin: -100px 0 40px 240px;

    @media (width >= 720px) {
      & {
        width: 108px;
        height: 108px;
        margin: -75px 0 30px 150px;
      }
    }

    @media (width >= 920px) {
      & {
        width: 162px;
        height: 162px;
        margin: -75px 0 30px 180px;
      }
    }

    @media (width >= 1220px) {
      & {
        width: 216px;
        height: 216px;
        margin: -100px 0 40px 200px;
      }
    }

    @media (width >= 1440px) {
      & {
        width: 216px;
        height: 216px;
        margin: -100px 0 40px 300px;
      }
    }
  }

  .subtitle {
    color: #d9d9d9;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.2;
    margin-top: -10px;
    margin-bottom: 40px;

    @media (width >= 720px) {
      & {
        font-size: 3rem;
      }
    }

    @media (width >= 1440px) {
      & {
        font-size: 3.4rem;
      }
    }
  }

  .description {
    color: #bbb;
    font-size: 1.2rem;
    margin-bottom: 40px;
  }

  .partners {
    margin-bottom: 40px;

    a {
      font-size: 1.4rem;
      text-decoration: none;
      font-weight: bold;
      color: #fff;
      margin-right: 3.6rem;
      opacity: 0.8;
      white-space: nowrap;

      @media (width >= 1440px) {
        & {
          font-size: 2rem;
        }
      }

      &:hover {
        color: #fff;
        opacity: 1;
      }

      svg {
        vertical-align: middle;
        margin-right: 1rem;
      }

      span {
        vertical-align: middle;
      }

      &.deworker {
        svg {
          margin-left: -4px;
          margin-right: 0.4rem;
        }
      }

      &.failover {
        svg {
          margin-top: -4px;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 40px;

    a {
      display: block;
      flex-basis: calc(50% - 8px);
      border-radius: 12px;
      font-size: 1rem;
      text-decoration: none;
      padding: 1.5rem 0.5rem;
      text-align: center;
      margin-right: 8px;
      border: 1px solid #d9d9d9;

      &:last-of-type {
        margin-right: 0;
      }

      &.lectures {
        background: #233781;
        color: #fff;
      }

      &.telegram {
        background: #fff;
        color: #233781;
      }
    }
  }
}
