@import 'reset';

html {
  font-size: 16px;
  min-height: 100vh;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.625;
  color: #222;
  background: #101c49;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

.container {
  max-width: 1620px;
  margin: 0 auto;
  padding: 0 30px;
}

.app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

a {
  color: #1465b7;
}

.nobr {
  white-space: nowrap;
}

.app-content {
  padding: 40px 0 0;
  font-size: 2rem;

  h1 {
    line-height: 1.2;
    font-size: 3.2rem;
    font-weight: normal;
    color: #333;
    margin-bottom: 40px;
  }

  h2 {
    line-height: 1.2;
    font-size: 2.4rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 40px;
  }

  ul {
    margin-bottom: 40px;
    list-style-position: inside;
  }
}

.responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: '';
  }

  video,
  img,
  iframe {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &.responsive-16x9 {
    &::before {
      padding-top: calc(9 / 16 * 100%);
    }
  }
}

.button {
  display: inline-block;
  border: 1px solid #2d6ba1;
  background: #337ab7;
  padding: 20px 30px;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.8rem;
  text-decoration: none;
  line-height: 1.2;
}

.form {
  .input-row {
    margin-bottom: 40px;

    label.input-label {
      display: block;
      margin-bottom: 4px;
      font-weight: bold;
      font-size: 1.5rem;
    }

    .input {
      display: block;
      width: 100%;
      color: #444;
      border: 1px solid #ddd;
      background: #fff;
      border-radius: 12px;
      padding: 28px 32px;
      font-size: 1.4rem;
      font-family: Arial, sans-serif;
    }

    input[type='checkbox'] {
      width: 32px;
      height: 32px;
    }

    .error {
      margin-top: 8px;
      font-size: 1.3rem;
      color: #c82333;
    }
  }

  .button-row {
    padding-top: 10px;

    button {
      border: none;
      background: #11112b;
      padding: 28px 44px;
      color: #fff;
      border-radius: 8px;
      cursor: pointer;
      font-size: 1.6rem;
      text-transform: uppercase;
      font-weight: bold;

      &.submit {
        background: #11112b;
        color: #fff;
      }

      &:disabled {
        cursor: wait;
      }
    }
  }
}
