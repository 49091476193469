.footer {
  background: #101c49;

  .wrapper {
    padding: 40px 0;
    display: flex;
    flex-flow: row wrap;
    font-size: 0.9rem;
    line-height: 1.3;

    .copyright {
      margin-right: auto;
      color: #999;
    }
  }
}
