.form {
  background: #d5d9e5;
  box-shadow: 0 0 30px 20px rgba(255 255 255 / 20%);
  max-width: 960px;
  padding: 40px;
  border-radius: 40px;
  margin: 0 auto;

  h1 {
    color: #233781;
    font-weight: bold;
    font-size: 3.6rem;
    margin-bottom: 1rem;
  }

  .offer {
    color: #233781;
    font-size: 1.4rem;
    line-height: 1.4;
  }

  .agree {
    display: flex;
    flex-flow: row wrap;
    align-items: start;

    span {
      width: calc(100% - 60px);
      margin-left: auto;
      font-size: 1rem;
      color: #233781;
      line-height: 1.4;
    }
  }

  .buttons {
    text-align: right;
    padding-top: 0;
  }
}

.half {
  @media (width >= 1400px) {
    & {
      display: flex;
      flex-flow: row wrap;
      margin-right: -40px;

      & > div {
        width: calc(50% - 40px);
        margin-right: 40px;
      }
    }
  }
}
