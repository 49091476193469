.anons {
  padding: 60px 0;
  background: #d5d9e5;

  h1 {
    color: #101c49;
    font-size: 6rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 4rem;
  }

  .date {
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 1.2;
    color: #233781;
    margin-bottom: 2.8rem;
  }

  .lectures {
    .item {
      margin: 60px 0;

      .meta {
        margin-bottom: 2rem;

        .number {
          display: table-cell;
          background: #233781;
          color: #fff;
          font-size: 1.2rem;
          border-radius: 400px;
          width: 72px;
          height: 72px;
          text-align: center;
          vertical-align: middle;
        }
      }

      .content {
        display: flex;
        flex-flow: row wrap;

        .author {
          width: 560px;

          .name {
            color: #111b47;
            font-size: 3rem;
            font-weight: bold;
            line-height: 1.2;
            margin-bottom: 0.8rem;
          }

          .position {
            color: #101c49;
            font-size: 1.3rem;
            line-height: 1.2;
            margin-bottom: 2rem;
          }
        }

        .description {
          width: 100%;
          color: #111b47;
          font-size: 1.3rem;
          line-height: 1.3;
          margin-bottom: 2rem;
          margin-top: 15px;

          @media (width >= 1200px) {
            & {
              width: calc(100% - 640px);
            }
          }

          p {
            font-weight: bold;
          }
        }
      }
    }
  }
}
