.header {
  background: #101c49;

  .wrapper {
    padding: 60px 0;
    display: flex;
    flex-flow: row wrap;

    .logo {
      font-family: Arial, sans-serif;
      font-size: 2rem;
      line-height: 1;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
      margin-right: auto;

      a {
        color: #fff;
        text-decoration: none;
      }
    }

    .nav {
      ul {
        list-style: none;

        li {
          display: inline-block;
          margin-left: 2rem;
          font-size: 0.9rem;
          text-transform: uppercase;

          a {
            line-height: 1;
            color: #fff;
            text-decoration: none;
          }

          &.active {
            a {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
